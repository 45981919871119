import React, { useEffect, useState, useRef } from 'react';
import io from 'socket.io-client';
import { getUserData } from '../getUserData';
import './PrivateChat.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
const PrivateChat = () => {
  const userData = getUserData();
  const userId = userData ? userData.userId : null;
  const userName = userData ? userData.userName : null;
  const myRole = userData?.role; // User role (user/agent)
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  
  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [receiverId, setReceiverId] = useState('');
  const [receiverName, setReceiverName] = useState('');
  const [receiverRole, setReceiverRole] = useState(''); // Role of the receiver (user/agent)
  const [usersAndAgents, setUsersAndAgents] = useState([]);
  const [chats, setChats] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const socketRef = useRef(null);

  useEffect(() => {
    socketRef.current = io('https://uniconnect.africa'); // Ensure this is the correct server URL

    // Fetch chat history
    const storedChats = JSON.parse(localStorage.getItem('recentChats')) || [];
    setChats(storedChats);

    fetch(`https://uniconnect.africa/api/chat/history/${userId}/${myRole}`)
      .then(res => res.json())
      .then(data => {
        const uniqueChats = Array.isArray(data) ? [...new Set([...storedChats, ...data])] : storedChats;
        setChats(uniqueChats);
        localStorage.setItem('recentChats', JSON.stringify(uniqueChats));
      })
      .catch(error => console.error('Error fetching chat history:', error));

    socketRef.current.on('receivePrivateMessage', (message) => {
      setMessages(prev => [...prev, message]);
    });

    return () => {
      socketRef.current.off();
      socketRef.current.disconnect();
    };
  }, [userId, myRole]);

  useEffect(() => {
    if (receiverId && receiverRole) {
      fetch(`https://uniconnect.africa/api/chat/private/${userId}/${myRole}/${receiverId}/${receiverRole}`)
        .then(res => res.json())
        .then(data => {
          console.log('Fetched Messages:', data); // Debugging
          setMessages(Array.isArray(data) ? data : []);
        })
        .catch(error => console.error('Error fetching messages:', error));
    }
  }, [receiverId, receiverRole, userId, myRole]);
  
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (newMessage.trim() === '' || !receiverId) return;

    const messageData = {
      senderId: userId,
      receiverId,
      senderName: userName,
      receiverName,
      message: newMessage.trim(),
      timestamp: new Date().toISOString(),
      senderType: myRole, // Sender type (user/agent)
      receiverType: receiverRole, // Receiver type (user/agent)
    };

    try {
      console.log(userName);
      console.log(messageData);
      socketRef.current.emit('sendPrivateMessage', messageData);  // Emit message
      await fetch('https://uniconnect.africa/api/chat/private', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(messageData),
      });

      setMessages(prev => [...prev, messageData]);
      setNewMessage('');
      const updatedChats = [
        ...chats.filter(chat => chat.receiverId !== receiverId),
        { receiverId, receiverName, receiverType: receiverRole }, // Ensure receiverRole is added
      ];
      setChats(updatedChats);
      localStorage.setItem('recentChats', JSON.stringify(updatedChats));      
    } catch (err) {
      console.error('Error sending message:', err);
    }
  };

  const handleUserSelect = (chat) => {
    console.log('Selected Chat or User:', chat);
  
    setReceiverId(chat.id); // Set receiver ID
    setReceiverName(chat.name); // Set receiver Name
    setReceiverRole(chat.type); // Set receiver Role
    setIsSidebarOpen(false);
    // Fetch previous chat history
    fetch(`https://uniconnect.africa/api/chat/private/${userId}/${myRole}/${chat.id}/${chat.type}`)
      .then(res => res.json())
      .then(data => {
        console.log('Fetched Messages for selected chat:', data); // Debugging
        setMessages(Array.isArray(data) ? data : []);
      })
      .catch(error => console.error('Error fetching messages for chat:', error));
  };
  

  const handleSearch = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await fetch(`https://uniconnect.africa/api/chat/search/${searchTerm}`);
      const data = await response.json();
      setUsersAndAgents(Array.isArray(data) ? data : []);
    } catch (error) {
      setError('Error fetching users and agents');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="private-chat-container">
       <button onClick={toggleSidebar} className="toggle-sidebar-button">
        <FontAwesomeIcon icon={isSidebarOpen ? faTimes : faBars} />
      </button>
        {isSidebarOpen && (
        <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
          <div className="search-section">
            <h2>Search</h2>
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search by Name or Email..."
              className="search-input"
            />
            <button onClick={handleSearch} disabled={loading} className="search-button">
              {loading ? "Searching..." : "Search"}
            </button>
          </div>

          <div className="user-list">
            <h3>Users and Agents</h3>
            {usersAndAgents.map((user) => (
              <div
                key={user.id}
                onClick={() => handleUserSelect({ id: user.id, name: user.name, type: user.type })}
                className="user-item"
              >
                <p>{user.name} ({user.email})</p>
              </div>
            ))}
          </div>

          <div className="chats-list">
            <h3>Recent Chats</h3>
            {chats.length > 0 ? (
              chats.map((chat, idx) => (
                <div
                  key={idx}
                  onClick={() => handleUserSelect({ id: chat.receiverId, name: chat.receiverName, type: chat.receiverType })}
                  className="chat-item"
                >
                  <p>{chat.receiverName}</p>
                </div>
              ))
            ) : (
              <p>No recent chats</p>
            )}
          </div>
        </div>
      )}


      <div className="chat-window">
        <h2>Chat with {receiverName}</h2>
              <div className="message-list">
        {messages.length === 0 ? (
          <p>No messages yet. Start the conversation!</p>
        ) : (
          messages.map((msg, idx) => (
            <div key={idx} className={`message ${msg.senderId === userId ? 'sent' : 'received'}`}>
              <strong>{msg.senderName}:</strong>
              <p>{msg.message}</p>
              <span>{new Date(msg.timestamp).toLocaleString()}</span>
            </div>
          ))
        )}
      </div>
        <form onSubmit={handleSendMessage} className="send-message-form">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type a message"
          />
          <button type="submit">Send</button>
        </form>
      </div>
    </div>
  );
};

export default PrivateChat;
