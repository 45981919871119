import React from 'react';
const HowItWorks = () => {
  return (
    <section className="how-it-works">
      <h2 className='how-title'>How It Works</h2>
      <img className="how-img" src="images/howto.jpg"/>
    </section>
  );
};

export default HowItWorks;
