// Header.js
import React from 'react';

function Header() {
    return (
        <header className="agent-header">
            <img className="logo" src="/images/logos.jpg"/>
            <h1 className="AgentDashboard">AgentDashboard</h1>
            <img className="person" src="/images/person-icon.png"/>
        </header>
    );
}

export default Header;
